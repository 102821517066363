.container {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 0;
  row-gap: 0;
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .container {
    padding-top: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 10px;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1280px) {
}
