.container {
  box-sizing: border-box;
  background-color: white;
  border-radius: 0;
  padding: 1rem;
  border-bottom: 1px solid grey;
}

.container:hover {
  cursor: pointer;
  background-color: #fafafa;
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .container {
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    border-bottom: none;
  }
}

@media (min-width: 1280px) {
}

.logo {
  box-sizing: border-box;
}
.content > p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.footer::before {
  content: "";
  width: 44px;
  height: 0;
  border-top: 1px solid black;
  margin: 8px 0;
  order: -1;
}

.footer::after {
  content: "";
  flex-basis: 100%;
  order: -1;
}
