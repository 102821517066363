// --------------------------------------------------------------
// ------------------------ Main Styling ------------------------
// --------------------------------------------------------------

$main-color: #6557b8;
$main-color-dark: #514698;

$secondary-color: #e4a400;
$secondary-color-dark: #d69a00;
$secondary-color-darker: #bd8700;

$text-color-light: #ffffff;
$text-color-dark: #242424;

// --------------------------------------------------------------
// ---------------------- Component Styling ---------------------
// --------------------------------------------------------------

.container {
  box-sizing: border-box;
  width: 100%;
  background-color: $main-color;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  padding: 2.5em 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "slug"
    "keyword"
    "location"
    "button";
  column-gap: 15px;
  row-gap: 15px;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container {
    padding: 4em;
    grid-template-columns: 4fr 4fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "slug slug"
      "keyword location"
      "button button";
  }
}

// Large devices (desktops, 900px and up)
@media (min-width: 900px) {
  .container {
    border-radius: 5px;
    grid-template-columns: 4fr 4fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "slug slug slug"
      "keyword location button";
  }
}

// ------------------------ Grid/Form Elements ------------------------

.slug {
  grid-area: slug;
  color: white;
  font-family: "Inria Sans", sans-serif;
  font-size: 24pt;
  padding-bottom: 1rem;
}

.keyword {
  grid-area: keyword;
}

.location {
  grid-area: location;
}

.input {
  height: 54px;
  background: white;
  border: 2px solid $main-color;
  border-radius: 5px;
  font-size: 12pt;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem;
}

.input:focus {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid white;
  outline-width: 0;
}

.button {
  grid-area: button;
  align-self: center;

  height: 54px;

  color: white;
  background: $secondary-color;

  border-radius: 5px;
  font-size: 15pt;
  box-sizing: border-box;
  padding: 0.8rem 0.2rem;
  border: none;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.button:hover,
button:active {
  background: $secondary-color-dark;
  outline-width: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.button:focus {
  background: $secondary-color-dark;
  outline-width: 0;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.4);
}
