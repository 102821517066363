// --------------------------------------------------------------
// ------------------------ Main Styling ------------------------
// --------------------------------------------------------------

$main-color: #6557b8;
$main-color-dark: #514698;

$secondary-color: #e4a400;
$secondary-color-dark: #d69a00;
$secondary-color-darker: #bd8700;

$text-color-light: #ffffff;
$text-color-dark: #242424;

// --------------------------------------------------------------
// ---------------------- Component Styling ---------------------
// --------------------------------------------------------------

.separator {
  background-color: $secondary-color;
  width: 100%;
  height: 0.25rem;
}

.footer {
  background-color: white;
  font-size: 10pt;
  color: $text-color-dark;
}

.container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
}

@media (min-width: 900px) {
  .container {
    padding: 1rem 0;
  }
}
