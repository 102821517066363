// --------------------------------------------------------------
// ------------------------ Main Styling ------------------------
// --------------------------------------------------------------

$main-color: #6557b8;
$main-color-dark: #514698;

$secondary-color: #e4a400;
$secondary-color-dark: #d69a00;
$secondary-color-darker: #bd8700;

$text-color-light: #ffffff;
$text-color-dark: #242424;

// --------------------------------------------------------------
// ---------------------- Component Styling ---------------------
// --------------------------------------------------------------

.navbar {
  background-color: white;
  border-bottom: #c5bcc9 1px solid;
  height: 4rem;
}

.container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
}

@media (min-width: 900px) {
  .container {
    padding: 1rem 0;
  }
}

.container > a {
  color: $main-color;
  font-weight: bold;
  text-decoration: none;
}

.container > a:hover {
  color: $main-color;
  font-weight: bold;
  text-decoration: underline;
}

.img {
  max-height: 100%;
}
