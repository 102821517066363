.loader {
  border: 0.3rem solid #f3f3f3; /* Light grey */
  border-top: 0.3rem solid #555;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  align-self: center;
  margin-top: 2rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
