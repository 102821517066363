.view {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f4f2ec;
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .content {
    padding-top: 1rem;
  }
}

@media (min-width: 1280px) {
}
