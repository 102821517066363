// this CSS sets the container breakpoints for navbar, footer and content

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .container {
    width: 90vw;
    max-width: 1216px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) {
}
